<template>
  <div id="auth-data">
    <div class="heading">
      <span>Inscreva-se na <strong>Inside Brasil</strong></span>
    </div>
    <div ref="form" autocomplete="off" class="form">
      <text-field
        id="email"
        label="E-mail"
        v-model="user.email"
        type="e-mail"
        :value="user.email"
        placeholder="example@mail.com"
        :textFieldError="emailVerification"
      />
      <text-field
        id="email"
        label=""
        v-model="user.email_confirmation"
        type="e-mail"
        :value="user.email_confirmation"
        placeholder="Confirme seu e-mail"
        :textFieldError="emailVerification"
      />
      <text-field
        id="password"
        label="Senha"
        v-model="user.password"
        type="password"
        :value="user.password"
        placeholder="Senha"
        :textFieldError="passwordVerification"
      />
      <text-field
        id="password"
        label=""
        v-model="user.password_confirmation"
        type="password"
        :value="user.password_confirmation"
        placeholder="Confirme sua senha"
        :textFieldError="passwordVerification"
      />
      <custom-button
        color="primary"
        @click="signUp()"
        :loading="loading"
        :disabled="!isValidEmail"
        text="Confirmar"
      />
      <div class="text-description">
        <span>
          Ao clicar em <strong>"Confirmar"</strong>, você concorda com os
          <a
            target="_blank"
            href="https://ilumeo-brasil-com-br.s3.sa-east-1.amazonaws.com/termos-e-condicoes-de-uso-inside+brasil.pdf"
          >
            Termos de Uso
          </a>
          e
          <a
            target="_blank"
            href="https://ilumeo-brasil-com-br.s3.sa-east-1.amazonaws.com/politica-de-privacidade-inside-brasil.pdf"
          >
            Política de Privacidade
          </a>
        </span>
      </div>
      <custom-button
        color="primary"
        @click="$router.push({ name: 'login' })"
        text="Voltar"
        outlined
      />
      <div class="text-description">
        <span
          >A parceria da InsideBrasil com o PayPal tem como principal propósito
          facilitar a vida do ativador, tornando os pagamentos mais seguros,
          pontuais e frequentes. Por isso, todos os pagamentos são feitos
          exclusivamente por PayPal e é preciso ter uma conta nele com o mesmo
          e-mail que você está cadastrando no site da InsideBrasil para
          receber.</span
        >
      </div>
      <div class="text-description">
        <span>
          <strong
            ><a
              href="https://s3-sa-east-1.amazonaws.com/ilumeo-brasil-com-br/Passo%2Ba%2BPasso%2BPayPal.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>Clique aqui </strong></a
            ></strong
          >
          para ver um passo a passo de como criar sua conta no PayPal até
          receber seu primeiro pagamento.
        </span>
      </div>
    </div>
    <custom-modal v-model="show" @confirm="confirm" @cancel="cancel">
      <span slot="title">
        <span class="title text-center" v-text="modal.title"></span>
      </span>
      <div slot="content" class="text-center">
        <span class="content-modal" v-text="modal.description"> </span>
        <div class="mt-10 text-center email">
          <strong>{{ user.email }}</strong>
        </div>
      </div>
      <div slot="actions" class="d-flex text-center">
        <custom-button icon color="primary" @click="confirm" text="Confirmar"
          ><div slot="icon">
            <span class="material-icons material-icons-outlined">
              arrow_forward
            </span>
          </div></custom-button
        >
      </div>
    </custom-modal>
  </div>
</template>

<script>
import auth from "@/api/services/auth.js";
import TextField from "@/components/Form/TextField/";
import CustomModal from "@/components/Modal/";
import CustomButton from "@/components/Button";
import { validateEmail } from "@/utils/validators";

export default {
  components: {
    TextField,
    CustomModal,
    CustomButton,
  },
  mounted() {
    localStorage.clear();
  },
  data() {
    return {
      user: {
        email: "",
        email_confirmation: "",
        password: "",
        password_confirmation: "",
        accept_policy_term: true,
        parent_user_recommendation_code: null,
      },
      errors: [],
      errorMail: null,
      isEmailValid: false,
      show: false,
      loading: false,
      disabled: false,
      modal: {
        title: null,
        description: null,
        action: null,
      },
    };
  },
  computed: {
    isValidEmail() {
      return validateEmail(this.user.email);
    },

    passwordVerification() {
      return this.user.password !== this.user.password_confirmation
        ? "As senhas não coincidem"
        : null;
    },
    emailVerification() {
      return this.user.email !== this.user.email_confirmation
        ? "Os e-mails não coincidem"
        : null;
    },
  },
  methods: {
    checkForm() {
      return (
        this.user.email &&
        this.user.email === this.user.email_confirmation &&
        this.user.password &&
        this.user.password === this.user.password_confirmation
      );
    },
    async signUp() {
      if (this.loading) return;

      this.disabled = true;
      this.loading = true;

      this.user.parent_user_recommendation_code = this.$route.query.code;
      if (this.checkForm()) {
        await auth.signUp(this.user).then(
          () => {
            this.show = true;
            this.modal.title = "E-mail de verificação enviado!";
            this.modal.description =
              "Enviamos um e-mail de verificação para continuar o seu cadastro. Por favor, verifique sua caixa de entrada e siga as instruções. E-mail enviado para:";
            this.modal.action = "success";
            this.disabled = false;
            this.loading = false;
          },
          (error) => {
            this.disabled = false;
            this.loading = false;

            if (error.response.status === 401) this.show = true;
            this.modal.title = "E-mail já cadastrado!";
            this.modal.description =
              "Enviamos um e-mail de verificação para recuperar o seu cadastro. Por favor, verifique sua caixa de entrada e siga as instruções. E-mail enviado para:";
            this.modal.action = "success";
            this.resendMail();
          }
        );
      } else {
        this.loading = false;
      }
    },
    confirm() {
      // some code...
      this.show = false;
      this.$router.push({ name: "login" });
    },
    cancel(close) {
      // some code...
      close();
    },
    async resendMail() {
      await auth.resendMail({ email: this.user.email });
      // some code...
    },
  },
};
</script>

<style scoped>
.heading {
  color: #0d0c22;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font-size: 1.5rem;
}

.form {
  display: flex;
  flex-direction: column;
}
.text-description {
  margin: 15px 0px;
  font-size: 12px;
  text-align: justify;
  color: #8c8c8c;
}
.title {
  padding: 10px 0px;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
}
@media only screen and (max-width: 768px) {
  .title {
    padding: 10px 0px;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
  }
  .content-modal {
    font-size: 0.875rem;
    text-align: justify;
  }
  .email {
    font-size: 0.875rem;
  }
}
</style>
